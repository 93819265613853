module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.17.0__react-dom@18.3._wron33vi3ikcnguzagrpmkx534/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.17.0__react-dom@18.3_7dt2yy57cqvluswyz6stwkt44i/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"title":"MarketArt Tattoo","name":"MarketArt Tattoo","short_name":"MarketArt","start_url":"/","author":"MarketArt Tattoo","background_color":"#c7c3c0","theme_color":"#2f2f2f","display":"standalone","icon":"src/images/marketart-icon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"74806ee9984a1ab38044e8db4128b5d2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.17.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__t6ywht7vzigjdiwfjiwjvplvqa/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
